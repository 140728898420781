import { createRouter, createWebHistory } from "vue-router";

let routes = [
    {
        path: "/",
        redirect: "/index/home",
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/views/agreement/PrivacyView.vue"),
        meta: {
            hideTabbar: true,
        },
    },
    {
        path: "/yinsi",
        name: "yinsi",
        component: () => import("@/views/agreement/yinsi.vue"),
        meta: {
            hideTabbar: true,
        },
    },
    {
        path: "/index",
        name: "index",
        component: () => import("@/views/index.vue"),
        redirect: "/index/home",
        children: [
            {
                path: "home",
                name: "home",
                component: () => import("@/views/home/index.vue"),
            },
            {
                path: "video",
                name: "video",
                component: () => import("@/views/video/index.vue"),
            },
            {
                path: "zhibo",
                name: "zhibo",
                component: () => import("@/views/zhibo/index.vue"),
            },
            {
                path: "mine",
                name: "mine",
                component: () => import("@/views/mine/index.vue"),
            },
        ],
    },

    {
        path: "/equityExchange",
        name: "equityExchange",
        component: () => import("@/views/home/components/equityExchange.vue"),
    },
    {
        path: "/goodsDetail",
        name: "goodsDetail",
        component: () => import("@/views/home/components/goodsDetail.vue"),
    },
    //视频页
    {
        path: "/playVideo",
        name: "playVideo",
        component: () => import("@/views/video/components/playVideo.vue"),
    },
    {
        // 客服
        path: "/kefu",
        name: "klefu",
        component: () => import("@/components/kefu/index.vue"),
    },
    {
        // 客服
        path: "/inLineKefu",
        name: "inLineKefu",
        component: () => import("@/components/kefu/components/inLineKefu.vue"),
    },
    {
        // 系统设置
        path: "/setting",
        name: "setting",
        component: () => import("@/views/mine/components/setting.vue"),
    },
    {
        // 提现
        path: "/tixian",
        name: "tixian",
        component: () => import("@/components/Tixian/index.vue"),
    },
    {
        // 提现金额
        path: "/countMoney",
        name: "countMoney",
        component: () => import("@/components/Tixian/components/countMoney.vue"),
    },
    {
        // 提现记录
        path: "/tixianHistory",
        name: "tixianHistory",
        component: () => import("@/components/Tixian/components/tixianHistory.vue"),
    },
    {
        // 绑定支付宝
        path: "/bandAliPay",
        name: "bandAliPay",
        component: () => import("@/components/Tixian/components/bandAliPay.vue"),
    },
    {
        // 订单中心
        path: "/order",
        name: "order",
        component: () => import("@/views/mine/components/order.vue"),
    },
    {
        // 订单中心
        path: "/AndroidPrivacy",
        name: "AndroidPrivacy",
        component: () => import("@/views/AndroidPrivacy.vue"),
    },
    {
        // 订单中心
        path: "/AndroidYinSi",
        name: "AndroidYinSi",
        component: () => import("@/views/AndroidYinSi.vue"),
    }
];

// 3. 创建路由实例并传递 `routes` 配置。
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // 将通用路由和模块路由合并
    routes: routes,
});
export default router;
