<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App",
    components: {},
    // 接下来的使用就跟之前一样，初始化图表，设置配置项
};
</script>

<style lang="less">
#app {}
</style>
