import { createApp } from "vue";
import App from "./App.vue";
import "@/utils/rem";
import "vant/lib/index.css";
import {
    Button,
    Tabbar,
    TabbarItem,
    Icon,
    Loading,
    Swipe,
    SwipeItem,
    Sticky,
} from "vant";
import { Image as VanImage } from "vant";
// 引入路由
import router from "./router";
import "./assets/baseCss.css";
let app = createApp(App);
app.use(router);
app.use(Button);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Icon);
app.use(VanImage);
app.use(Loading);
app.use(Swipe);
app.use(SwipeItem);
app.use(Sticky);
app.mount("#app");

window.ontouchstart = function (event) {
    event.preventDefault();
};
// import VConsole from "vconsole";
// const vConsole = new VConsole();
// console.log(vConsole);

